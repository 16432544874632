import React, { useCallback, useState } from 'react';
import DatePicker from 'react-datepicker';
import Picker from 'react-multi-date-picker';
import TimePicker from 'react-multi-date-picker/plugins/time_picker';

// Icons
import { FaCalendarAlt } from 'react-icons/fa';
import { Button, FormControl } from '@mui/material';
import { MdOutlineTimer } from 'react-icons/md';

// Styles
import './style.css';
import '../Wallboard Management/CreateWallboard/style.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import { API, Postup_APIS } from 'api/API';
import Axios from 'api/Axios';
import { formatDateAndTime } from 'functions/Functions';

const EmailScheduler = ({ isLoading, close, setIsLoading, setSnackbar, uploadAssetsData }) => {
  const date = new Date();
  const [time, setTime] = useState('00:00:00');
  const [selectedDate, setSelectedDate] = useState(date);
  const [errors, setErrors] = useState({ date: '', time: '' }); 


  // Optimized handler for date changes using useCallback
  const handleDateChange = useCallback(
    date => {
      if (date !== selectedDate) {
        setSelectedDate(date);
        setErrors(prev => ({ ...prev, date: '' })); // Clear date error on valid selection
      }
    },
    [selectedDate]
  );

  const handlePickerChange = date => {
    const formattedTime = date?.format('HH:mm:ss') || '00:00:00';
    setTime(formattedTime);
    setErrors(prev => ({ ...prev, time: '' })); // Clear time error on valid selection
  };

  const schedule_Email = async () => {
    let hasError = false;
    if (!selectedDate) {
      setErrors(prev => ({ ...prev, date: 'Please select a date' }));
      hasError = true;
    }
    if (!time || time === '00:00:00') {
      setErrors(prev => ({ ...prev, time: 'Please select a time' }));
      hasError = true;
    }

    if (hasError) return; // Exit if there are validation errors
    const data = {
      file_name: uploadAssetsData?.file_name,
      scheduledDate: formatDateAndTime(selectedDate, time),
    };
    try {
      setIsLoading(true);
      const response = await Axios.post(Postup_APIS.Postup_Scheduler, data);
      if (response.status === 200) {
        setIsLoading(false);
        setSnackbar({ open: true, message: response.data.msg, severity: 'success' });
        close();
      }
    } catch (err) {
      console.log(err, 'Error while getting API Response');
      setIsLoading(false);
      setSnackbar({ open: true, message: err?.message, severity: 'error' });
    }
  };

  return (
    <div className="calendar_overflow">
      <div className="calendar_and_time">
        <FormControl style={{ marginTop: 12, marginBottom: 0 }} fullWidth className="select_year">
          <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            dateFormat="dd/MM/yyyy"
            showMonthYearPicker={false}
            showFullMonthYearPicker={true}
            placeholderText="Select Date"
            className="date-input"
            open={true} // Force the calendar to always stay open
          />

          <FaCalendarAlt className="calendaralt datecalendar" />
          {errors.date && <p className="error-text-date">{errors.date}</p>} 
        </FormControl>
      </div>

      {/* Time Picker */}
      <FormControl
        style={{ marginTop: 12, marginBottom: 0 }}
        className="select_year times"
        fullWidth
      >
        <div>
          <Picker
            className="time_picker"
            disableDayPicker
            onChange={handlePickerChange}
            format="HH:mm:ss"
            plugins={[<TimePicker />]}
            placeholder="Select Time"
          />
          <MdOutlineTimer className="Timeralt" />
          {errors.time && <p className="error-text">{errors.time}</p>}
        </div>
      </FormControl>

      <div className="sendcancel" style={{ textAlign: 'center' }}>
        <Button
          size="large"
          type="submit"
          variant="contained"
          style={{ background: '#15223f', marginRight: '15px' }}
          onClick={schedule_Email}
        >
          Send
        </Button>
        <Button
          size="large"
          variant="contained"
          style={{
            background: '#C62828',
            marginRight: '10px',
            color: isLoading === true && '#fff',
          }}
          onClick={close}
          disabled={isLoading === true ?? true}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default EmailScheduler;
