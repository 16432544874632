const STAGING = "dev"; // 'dev', 'prod', 'preview', 'local'

const BASE_URLS_Firestore = {
  local: "http://192.168.75.118:3000/",
  dev: "https://api-wau.primotech.us/",
  prod: "https://ai-api.physiciansweekly.com/",
  preview: "https://preview-ai-api.physiciansweekly.com/",
};

export const BASE_URL = BASE_URLS_Firestore[STAGING] || "";

const BASE_URLS_MY_SQL = {
  local: "http://192.168.75.118:8080/",
  preview: "https://preview-ai-api-print.physiciansweekly.com/",
  dev: "https://api-pre-wau.primotech.us/",
  prod: "https://api-preprint-ai.physiciansweekly.com/",
};

export const URL = BASE_URLS_MY_SQL[STAGING] || "";

export const PW_URL = "https://www.physiciansweekly.com/";


export const API = {
  Login: `${BASE_URL}api/user_login`,
  Register: `${BASE_URL}api/user_register`,
  Profile_Details: `${BASE_URL}api/getProfile`,
  Get_All_categories: `${BASE_URL}api/getAllCategories`,
  Create_template: `${BASE_URL}api/createtemplate`,
  Get_Template: `${BASE_URL}api/gettemplate`,
  TemplateCount: `${BASE_URL}api/getCountTemplates`,
  Delete_Template: `${BASE_URL}api/deleteTemplate/`,
  Get_template_dataBy_ID: `${BASE_URL}api/get-Template/`,
  Get_Network_Type: `${BASE_URL}api/get_network_type`,
  Create_Wallboard: `${BASE_URL}api/create_wallboard_pdfmerge`,
  Get_All_Wallboards: `${BASE_URL}api/get_all_wallboards_mergepdf_ai`,
  Copy_Wallboard: `${BASE_URL}api/copy_wallboard_pdfmerge`,
  CreateAI_Wallboard: `${BASE_URL}api/CreateaAIWallboard`,
  Delete_Wallboard: `${BASE_URL}api/Delete_Wallboard`,
  Get_AI_Wallboard_Listings: `${BASE_URL}api/GetAIWallboardListing`,
  Get_Validate_Content: `${BASE_URL}api/listing_all_content_management_data`,
  Get_Validate_Content_By_ID: `${BASE_URL}api/get_single_data`,
  Validate_Update_Content: `${BASE_URL}api/update_all_data`,
  // Pending_PDF: `${BASE_URL}api/getAllWallboardAIPDF`,
  Pending_PDF: `${BASE_URL}api/get_printer_created_WallboardAIPDF`,
  Get_Single_PDF: `${BASE_URL}api/get_printer_pdf_path`,
  Approval_disapprovalAIWallboard: `${BASE_URL}api/web_approved_disapproved_status`,
  Validate_Filter: `${BASE_URL}api/category_month_filter`,
  Get_Single_Version: `${BASE_URL}api/getSingleVersionAI`,
  Single_Listing_AI_Walboard: `${BASE_URL}api/getStatusListingAIWallboard`,
  Approve_and_Disapprove_Wallboard: `${BASE_URL}api/approveAndUploadPDF`,
  Wallboard_Version_List: `${BASE_URL}api/wallboardVersionList`,
  Wallboard_Update: `${BASE_URL}api/Wallboardversionsedit`,
  Wallboard_Filter_API: `${BASE_URL}api/versionFilterWallboard`,
  Get_Pdf_Path: `${BASE_URL}api/getPdfPath`,
  Invite_New_User: `${BASE_URL}api/invite_user`,
  Get_All_Filter_Category: `${BASE_URL}api/getAllCategories`,
  Get_All_Filter_Category_Subcategory: `${BASE_URL}api/getAllSubCategories`,
  Get_SubCategory: `${BASE_URL}api/get_subcategory`,
  Send_Email: `${BASE_URL}api/sendemail`,
  Themes_Listing: `${BASE_URL}api/get_all_template_themes`,
  Copy_Multiple_Wallboard: `${BASE_URL}api/CopysWallboards`,
  Delete_Multiple_Wallboard: `${BASE_URL}api/DeletesmultipleWallboards`,
  Filter_Create_Wallboard: `${BASE_URL}api/filter_by_month_year`,
  Get_Users_List: `${BASE_URL}api/get_user_listing`,
  Delete_Users: `${BASE_URL}api/delete_user`,
  Get_User_Detail: `${BASE_URL}api/getUserByID`,
  Update_User_Detail: `${BASE_URL}api/update_user`,
  Get_Profile: `${BASE_URL}api/getProfile`,
  Update_Profile: `${BASE_URL}api/updateProfile`,
  Forgot_Pass: `${BASE_URL}api/reset_link`,
  Generate_Password: `${BASE_URL}api/validate_save_resetpassword`,
  Wallboard_Existing_years: `${BASE_URL}api/getexistingyears`,
  Wallboard_YearsTo_Months: `${BASE_URL}api/getexistingyeartomonth`,
  Wallboard_Versions_GetYear: `${BASE_URL}api/wallboard_versions_getyear`,
  Wallboard_Versions_GetMonth: `${BASE_URL}api/wallboard_versions_getyeartomonth`,
  Wallboard_Versions: `${BASE_URL}api/getAllWallboardVersions`,
  Author_image_Delete: `${BASE_URL}api/delete_author_image`,
  PrePrintModule: `${URL}api/pre_print_data`,
  PrePrintListings: `${URL}api/get_pre_print_data`,
  ADD_CSV: `${URL}api/add_row`,
  CSV_LISTING: `${URL}api/csv_listing`,
  Post_Order_Filter: `${URL}api/post_order_filter`,
  Sublisting_PrePrint: `${URL}api/get_single_csv_data`,
  Single_Sub_Listing_Delete: `${URL}api/single_post_delete`,
  Listing_Delete: `${URL}api/all_post_delete`,
  RWO_Files: `${URL}api/get_file_name`,
  View_CSV_All_Data: `${URL}api/Find_all_csv_data`,
  Export_CSV_Data: `${URL}api/findAllCsvDataExport`,
  ChildListing_Preprint: `${URL}api/get_poster_order_listing`,
  PW_Media_Images: `${PW_URL}author-images-api`,
  PW_Media_Images1: `${PW_URL}api/wp/v2/author-images-api/`,
  Storing_Media_Image: `${BASE_URL}api/saved_media_image`,
  Upload_PrePrint_Assets: `${URL}api/add_assets`,
  AddQR: `${URL}api/add_Print_qr_data`,
  GetQRDataListings: `${URL}api/get_printable_data`,
  GetAssets: `${URL}api/get_assets`,
  Meta_data: `${URL}api/getallassets_datacollection`,
  Download_Assets: `${URL}api/export_file`,
  Default_QR_Values: `${URL}api/default_qr_values`,
  Get_Templates: `${URL}api/get_templates`,
  Edit_Assets: `${URL}api/Edit_assets`,
  Delete_ICYMI_Assets: `${URL}api/delete_assets_qr`,
  Edit_QR: `${URL}api/Edit_Qr`,
  Merge_PO_data: `${URL}api/merged_po_data`,
  Merge_Printables: `${URL}api/final_concat`,
  Add_hanger_Comp_Data: `${URL}api/add_hanger_comp_data`,
  Get_hanger_Comp_CSV_data: `${URL}api/get_hanger_comp_csv_poster_order`,
  Hanger_Comp_Merge: `${URL}api/hanger_comps_merged_po_data`,
  Export_Merge_Hanger_comp_Data: `${URL}api/Export_merged_hanger_comp_po_data`,
  API_Checks_For_Menu_Items: `${URL}api/check_hanger_comp_data`,
  Export_Merged_RWO: `${URL}api/download_assets`,
  Export_QR_Codes: `${URL}api/getmergeDataAndsendtoPHP`,
  Pre_Print_Final_Approve: `${URL}api/final_approve_PO`,
  Get_IP_Address: 'https://api.ipify.org?format=json',
  Get_All_User_Logs: `${BASE_URL}api/findAlluserataExport`,
  Get_Wallboard_Details: `${BASE_URL}api/getcopywallboardname`,
  Sync_Wordpress_Data: `${BASE_URL}api/runepythonscript`,
  AI_Generated_Summary: `${BASE_URL}api/summarize/`,
  Storing_AI_Summary: `${BASE_URL}api/Insert_Ai_gen_summary`,
  Getting_AI_Summary: `${BASE_URL}api/fetch_Ai_gen_summary`,
  Save_Original_Wallboard_Content: `${BASE_URL}api/save_orignal_content`,
  Getting_Original_Content: `${BASE_URL}api/get_orignal_content`,
  Change_PDF_Dimenssions:`${BASE_URL}api/changePdfDimensions`, 
  Reject_Multiple_Wallboards:`${BASE_URL}api/RejectMultiple`,
};

export const Auto_Tag_APIs= {
  Get_Month_Year:`${BASE_URL}api/get_month_year_for_auto_tag`,
  Auto_Tag_Wallboard:  `${BASE_URL}api/get_auto_tag_data`,
  Get_Poster_Codes: `${BASE_URL}api/get_poster_code_from_month_year`,
  Auto_tag_Wallboard_versions: `${BASE_URL}api/get_auto_tag_data_for_ICYMI_wise`,
  Get_ICYMI_Links: `${URL}api/get_icymi_links_from_poster_code`,
  Edit_Assets: `${URL}api/update_asserts_upload`,
  Update_SmartSheet: `${BASE_URL}api/insert_ssheet_data`,
}

export const Perks_APIS = {
  Perks_Listing: `${URL}api/get_RW_operk`,
  Perks_Details: `${URL}api/get_month_data`,
  Get_Poster_Codes: `${URL}api/get_poster_code_data`,
  Perks_File_names: `${URL}api/get_perk_file_name`,
  Perks_SmartSheet: `${URL}api/get_smartsheet_details`,
  Send_QR: `${URL}api/getmergeDataAndsendtoPHP`,
  Upload_Perks_PDF: `${URL}api/upadte_manual_pdf`,
  Update_SmartSheet_Status: `${URL}api/update_smartsheetstatus`,
};

export const Postup_APIS = {
  Postup_data_Upload: `${URL}api/Merge_RWO_Email_data`,
  Send_Campaign: `${URL}api/Postup_email_send`,
  Postup_Menu_Checks: `${URL}api/get_enable_status`,
  Preview_Email_List: `${URL}api/get_email_data`,
  // Get_RSS_feed: `${URL}api/get_rss_data`,
  Preview_Email: `${URL}api/get_articles_data`, 
  Approve_Disapprove_Poster: `${URL}api/update_merge_rwo_flag`,
  Filter_Email_Preview: `${URL}api/get_guid_lid_for_filter`,
  Multiple_Approve_Disapprove_Poster: `${URL}api/bulk_update_rwo_flag`,
  Email_Status:`${URL}api/Email_Status`,
  Postup_Scheduler:`${URL}api/Postup_email_send`,
};

export const Meta_DataAPIS = {
  Delete_Specific_Files: `${URL}api/Delete_0ne_assets`,
  Get_Meta_Data: `${URL}api/get_meta_data`,
  Copy_Meta_DataViaCount: `${URL}api/insert_copy_meta_data`,
};